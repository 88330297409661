import React from "react";
import Logo from '../images/logo.png'
import vision from '../images/vision.png'

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <img src={Logo} width="130px" alt="Logo" /> <img src={vision} width="136px" alt="vision" /> 
        
        
        

        <p>تواصل معنا</p>
        <div className="social-icons">
         
         
          
          <a href="https://twitter.com/bandarAXB7" target='_blank'>
            <i className="bi bi-twitter"></i>
          </a>
          



          <a href="https://instagram.com/xbandar91?igshid=YmMyMTA2M2Y=" target='_blank'>
            <i className="bi bi-instagram"></i>
          </a>

         

         
       

        </div>
        <p className="copyright">
        <span>[v1.4.2]</span> <a href="https://furqan2.com/">بندر</a>  ©2024 جميع الحقوق محفوظة
        </p>
 

       
 

      </div>
    </footer>
  );
};

export default Footer;
