import React from 'react'
import bbb from "../images/bbb.png";
const NotFound = () => {
  return (
    <section className='not-found'>
      <div className='container'>
        <h3>هذا الصفحة غير متوفرة حالياً...نعتذر منكم نعمل لشي يُليق بكم  <img src={bbb} width="22px" alt="bbb" /> </h3>
        <a href='/'>عد إلى الصفحة الرئيسية</a>
      </div>
    </section>
  )
}

export default NotFound
