import React from 'react'



const liva = () => {

  return   (
    
    <>




  <section className="libs">

  <body className="lib-card">
        
            <center>
          <  font color = "#0f8"> <h3>القران الكريم</h3></font> 
         
          <iframe width="400" height="400" src="https://www.youtube.com/embed/moQtMet7F7w?si=lAmtjIPq4ZDr2rv1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>         </center>
            

            
            <center> 
          <  font color = "#0f8"> <h3>السنة النبوية </h3></font> 

          <iframe width="400" height="400" src="https://www.youtube.com/embed/gbLhU27-QWc?si=NxRp8YtaK0OeDLHL" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

        </center>



            
          </body>
    </section>
    
    </>
  )
}

   
export default liva